/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Reset margins and set box-sizing */
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Fix viewport issues */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  background-color: #f7fafc;
  color: #2d3748;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  font-size: clamp(2rem, 5vw, 3.5rem);
}

h2 {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  margin-bottom: 1rem;
}

h3 {
  font-size: clamp(1.2rem, 3vw, 1.5rem);
  margin-bottom: 0.5rem;
}

p {
  margin: 0 0 1rem 0;
}

a {
  color: #3b82f6;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #2563eb;
}

button {
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

/* Add a class for gradient text */
.gradient-text {
  background: linear-gradient(135deg, #3b82f6 0%, #9333ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

/* Add some utility classes */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

.text-center {
  text-align: center;
}

/* Media query for better mobile responsiveness */
@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
  
  .container {
    padding: 0 16px;
  }
}